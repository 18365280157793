<template>
  <div
    class="about px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <TitleComponent
      title="Hazards of Electromagnetic Radiation to Ordnance"
      size="large"
    />
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2"></div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <Paragraph
          ><p>
            There exists a potential for ordnance containing electrically
            initiated devices to be adversely affected by electromagnetic
            radiation encountered during manufacture, storage, transportation,
            and deployment of such devices. This phenomenon, known in the
            industry as Hazards of Electromagnetic Radiation to Ordnance (HERO),
            is described in NAVSEA OP 3565/NAVAIR 16-1-529 Volume 2. &nbsp;SMS
            can provide procedures and precautions to mitigate this hazard as
            coupling of the electromagnetic environment (EME) and the energetic
            device can cause unintentional or premature operation, often with
            significant safety and/or reliability implications.
          </p>
          <p>
            Safety Management Services, Inc. (SMS) is experienced in assisting
            facilities utilizing these devices with determination of potential
            hazards throughout the life of a susceptible device. Maintaining a
            sufficient distance from RF transmitters is also an effective
            mitigation method as radio frequency transmitters emit
            electromagnetic fields that decrease in intensity with the distance
            from the transmitter. By determining the safe separation distance
            required for each device type a radiofrequency hazard map of the
            facility indicating safe zones and zones with potential for
            initiation due to the EME can be developed along with
            recommendations for mitigating this type of hazard.
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Hazards of Electromagnetic Radiation to Ordinance",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "Electrically initiated ordinances can be affected by Hazards of Electromagnetic Radiation to Ordinance (HERO) as described in NAVSEA OP 3565/NAVAIR 16-1-529 Volume 2. SMS can assist in mitigating these hazards."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>
